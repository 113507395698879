<template>
  <div class>
    <template v-if="!isShowDetail">
      <div class="condition-filtrate-box">
        <div class="left-item">
          <el-input
            class="select-width id-code-width"
            size="medium"
            v-model="queryForm.searchValue"
            placeholder="居民姓名/手机号/证件号"
          />

          <el-select
            class="select-width"
            size="medium"
            v-model="queryForm.country"
            filterable
            placeholder="国籍"
          >
            <el-option
              v-for="(item,v) in nationalitySelect"
              :key="v"
              :label="item.label"
              :value="item.label"
            />
          </el-select>

          <el-select
            class="select-width"
            size="medium"
            clearable
            v-model="queryForm.crowdTypeId"
            placeholder="人群分类"
          >
            <el-option
              v-for="item in typepopSelect"
              :key="item.id"
              :label="item.ruleName"
              :value="item.id"
            />
          </el-select>

          <el-select
            v-btn-permission="'008002002001'"
            class="select-width"
            size="medium"
            clearable
            v-model="queryForm.isolationPointId"
            placeholder="隔离点"
          >
            <el-option
              v-for="item in isolationList"
              :key="item.id"
              :label="item.orgName"
              :value="item.id"
            />
          </el-select>
        </div>

        <div class="right-item">
          <el-button type="primary" size="small" @click="queryListInfo">查询</el-button>
          <el-button type="primary" size="small" @click="resetQueryInfo">重置</el-button>
          <el-button
            type="primary"
            size="small"
            @click="exportListData"
            v-loading.fullscreen.lock="fullscreenLoading"
          >导出</el-button>
        </div>
      </div>
      <TableModel
        :is-show-select="true"
        :is-show-tabs="true"
        :is-fixed="true"
        :loading="loading"
        :table-data="taskList"
        table-size="medium"
        @handleSelectionChange="auditBatchRelieveIsolate"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="{ row }">
            <el-link type="primary" style="margin-right:10px;" @click="checkQuarantine(row, 1)">通过</el-link>
            <el-link type="primary" style="margin-right:10px;" @click="rejectQuarantine(row)">拒绝</el-link>
            <el-link type="primary" @click="userDetails(row)">详情</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="居民信息" width="120" />
        <el-table-column prop="cardNo" label="证件号" width="170" />
        <el-table-column prop="crowdTypeName" label="人群分类" width="170" />
        <el-table-column prop="leaveTime" label="离开风险区时间" width="170" />
        <el-table-column prop="arrivalTime" label="抵渝时间" width="170" />
        <el-table-column prop="regTime" label="入住时间" width="170" />
        <el-table-column prop="closelyTime" label="密接时间" width="170" />
        <el-table-column prop="startTime" label="开始隔离时间" width="170" />
        <el-table-column prop="endTime" label="预解除隔离时间" width="170" />
        <el-table-column prop="applyRelieveTime" label="解除隔离时间" width="170" />
        <el-table-column prop="crowdTypeName" label="状态" width="120">
          <template slot-scope="scope">
            <div style="white-space: nowrap" v-if="scope.row.status == 0">已解除</div>
            <div style="white-space: nowrap" v-if="scope.row.status == 1">隔离中</div>
          </template>
        </el-table-column>
        <el-table-column prop="transferType" label="转出类型" width="120">
          <template slot-scope="scope">
            <div style="white-space: nowrap" v-if="scope.row.transferType == 1">解除管控</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 2">送医治疗</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 3">送往隔离点</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 4">居家隔离</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 5">居家监测</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 6">街道接走</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 7">闭环转运</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 8">转为密接/次密接/参照密接</div>
            <div style="white-space: nowrap" v-if="scope.row.transferType == 8">其他</div>
          </template>
        </el-table-column>
        <el-table-column prop="roomNo" label="房间号" width="120" />
        <el-table-column prop="isolationPointName" label="隔离点" width="120" />
        <el-table-column prop="applyUserName" label="提交人" width="120" />
        <el-table-column prop="country" label="国籍" width="120" />
      </TableModel>
      <div class="table-bottom">
        <div class="operation-text">
          <span>已选中{{ ids.length }}项目</span>
          <span style="margin-left:20px">批量操作</span>
          <el-button
            type="primary"
            size="mini"
            style="margin-left:20px"
            :disabled="disabled"
            @click="multipleOperation(1)"
          >通过</el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-left:20px"
            :disabled="disabled"
            @click="multipleOperation(0)"
          >拒绝</el-button>
        </div>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-size="pageSize"
            :total="total"
            :page-sizes="[10, 20, 40, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
          ></el-pagination>
        </div>
      </div>
    </template>

    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <!-- <el-page-header @back="goBack"></el-page-header> -->

      <residentInformation ref="residentInformationRef"></residentInformation>
    </div>

    <!--拒绝提示-->
    <DelResidentDialog ref="DelResidentDialogRef" dialog-title="拒绝提示" />
    <!-- 批量通过弹框 -->
    <dialog-info
      :isShowCancel="true"
      :dialogVisible="adoptVisible"
      title="操作确认"
      @closeDialog="adoptClose"
      @cancelDialog="adoptClose"
      @confirmDialog="adopt"
    >
      <el-row type="flex" align="middle">
        <el-col :span="2">
          <i class="el-icon-question" style="color:#ff9900;font-size:24px;"></i>
        </el-col>
        <el-col :span="18">
          <span style="font-weight:bold;font-size:16px">确定要通过以下居民解除隔离申请吗？</span>
        </el-col>
      </el-row>
      <el-row style="margin-top:10px">
        <el-col :offset="2">
          <span>{{names}}</span>
        </el-col>
      </el-row>
    </dialog-info>
    <!-- 批量拒绝弹框 -->
    <dialog-info
      :isShowCancel="true"
      :dialogVisible="rejectVisible"
      title="拒绝确认"
      @closeDialog="rejectClose"
      @cancelDialog="rejectClose"
      @confirmDialog="reject"
    >
      <el-row>
        <el-col :offset="1">拒绝居民：{{names}}</el-col>
      </el-row>
      <el-row style="margin-top:10px">
        <el-col :offset="1">拒绝原因：</el-col>
      </el-row>
      <el-row style="margin-top:10px">
        <el-col :offset="1" :span="21">
          <el-form :model="rejectForm">
            <el-form-item>
              <el-input
                v-model="rejectForm.applyRefuseRemark"
                :rows="4"
                type="textarea"
                maxlength="50"
                show-word-limit
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </dialog-info>
  </div>
</template>

<script>
import {
  applyListApi,
  auditRelieveIsolateApi,
  exportRelieveTodoTaskApi,
  auditBatchRelieveIsolateApi
} from "../../api/Taskmanagement/upcomingTasks";
import TableModel from "../../components/TableModel";
import residentInformation from "../QuarantineRegistration/residentInformation";
import DelResidentDialog from "../QuarantineRegistration/modules/DelResidentDialog";
import { messageBox, importFile } from "../../utils/utils";
import { mapState } from "vuex";
import DialogInfo from "@/components/DialogInfo";

export default {
  name: "BacklogTask",
  components: {
    TableModel,
    residentInformation,
    DelResidentDialog,
    DialogInfo
  },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      fullscreenLoading: false,
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        searchValue: "",
        country: "",
        crowdTypeId: "",
        isolationPointId: ""
      },
      isShowDetail: false,
      xqformdd: {},
      taskList: [],
      ids: [], // 勾选选项id数组
      names: "", //勾选选项name 组成的字符串
      adoptVisible: false, // 批量通过对话框
      rejectVisible: false, //批量拒绝对话框
      rejectForm: {
        applyRefuseRemark: ""
      } //批量拒绝表单
    };
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList, // 隔离点列表数据
      typepopSelect: state => state.user.typepopSelect, // 人群分类
      nationalitySelect: state => state.user.nationalitySelect // 国籍
    }),
    disabled() {
      return this.ids.length > 0 ? false : true;
    }
  },

  created() {
    this.applyList();
  },

  methods: {
    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        exportType:"2",
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      let {
        searchValue,
        country,
        crowdTypeId,
        isolationPointId
      } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (country) params.country = country;
      if (crowdTypeId) params.crowdTypeId = crowdTypeId;
      if (isolationPointId) params.isolationPointId = isolationPointId;

      return params;
    },

    applyList() {
      this.loading = true;
      let params = this.setParamsInfo();
      applyListApi({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.taskList = data.list;
          this.total = data.totalRow;
        } else {
          this.$message.error(msg);
        }
        this.loading = false;
      });
    },

    // 条件查询
    queryListInfo() {
      this.pageNumber = 1;
      this.applyList();
    },

    // 导出
    exportListData() {
      let params = this.setParamsInfo();
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportRelieveTodoTaskApi(params).then(res => {
            this.fullscreenLoading = false;
            importFile(res.data, "解除隔离审核列表数据");
          });
        },
        "warning",
        "确定需要导出解除隔离审核列表数据吗？"
      );
    },

    // 重置条件
    resetQueryInfo() {
      this.queryForm = {
        searchValue: "",
        country: "",
        crowdTypeId: "",
        isolationPointId: ""
      };
      this.applyList();
    },

    // 通过
    checkQuarantine(row, type) {
      let params = {
        id: row.id,
        type
      };
      messageBox(
        () => {
          auditRelieveIsolateApi(params).then(res => {
            let { code, msg } = res.data;
            if (code == 200) {
              this.$message.success(msg);
              this.applyList();
            } else {
              this.$message.error(msg);
            }
          });
        },
        "warning",
        "确定要通过当前解除隔离信息吗？"
      );
    },

    // 拒绝
    rejectQuarantine(row) {
      this.$refs.DelResidentDialogRef.show(row.id);
    },
    // 点击详细资料
    async userDetails(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },

    // 返回上一页面
    goBack() {
      this.isShowDetail = false;
      this.applyList();
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.applyList();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.applyList();
    },
    //批量通过或者拒绝
    auditBatchRelieveIsolate(ids, names) {
      this.ids = ids;
      this.names = names.toString();
    },
    //关闭通过弹框
    adoptClose() {
      this.adoptVisible = false;
    },
    //关闭拒绝弹框
    rejectClose() {
      this.rejectVisible = false;
    },
    //批量通过或拒绝
    async multipleOperation(type) {
      if (type == 1) {
        this.adoptVisible = true;
      } else {
        this.rejectVisible = true;
      }
    },
    //通过
    async adopt() {
      const params = { idList: this.ids, type: 1 };
      const { data } = await auditBatchRelieveIsolateApi(params);
      this.adoptVisible = false;
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.applyList();
      }
    },
    //拒绝
    async reject() {
      if (!this.rejectForm.applyRefuseRemark) {
        this.$message.warning("请输入拒绝原因");
        return;
      }
      const params = {
        idList: this.ids,
        type: 0,
        applyRefuseRemark: this.rejectForm.applyRefuseRemark
      };
      // console.log(params);
      const { data } = await auditBatchRelieveIsolateApi(params);
      this.rejectVisible = false;
      if (data.code == 200) {
        this.$message.success(data.msg);
        this.applyList();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";
</style>

<style scoped>
.el-table >>> td {
  padding: 6px 0 !important;
}

::v-deep .el-table th:first-child > .cell {
  /*visibility: hidden !important;*/
}

.table-bottom {
  display: flex;
  justify-content: space-between;
  margin: 14px;
}

.operation-text {
  color: #606266;
}
</style>